import React from "react"
import { graphql } from "gatsby"
import '../styles/page.css'
import '../styles/case.css'
import { css } from '@emotion/react'
import Navbar from "../components/navbar-nonhome"
import ReactHtmlParser from 'react-html-parser'
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import {Trans, Link, I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next';


export default function Job({data, location, pageContext}) {
    if (data.wpJob.jobomschrijving.type == null)
                {var hideJobSpecs = "none"}
                else { hideJobSpecs = "block"}
    const {t} = useTranslation()
    const context = React.useContext(I18nextContext);
    const taal = context.language
    return (
    <Layout>
      <Helmet titleTemplate="%s | Asbitech, uw partner in het verwijderen van asbest"
            htmlAttributes={{
              lang: `${taal}`,
            }}
            >
            <title>Vacature: {data.wpJob.title}</title>
            <meta name="description" content={data.wpJob.content} />
            </Helmet>
    <Navbar></Navbar>
    <div className="jobcontainer headerpadding mobile">
          <div className="backtolistnieuws"><Link to="/jobs#vacatures"><Trans>Alle vacatures</Trans></Link></div>
            <div css={css`margin-bottom: 1rem; width: 100%; display: ${hideJobSpecs}`}><span css={css`margin-right: 0.5rem;`} className="nieuws">{data.wpJob.jobomschrijving.type}</span><span className="project">{data.wpJob.jobomschrijving.duur}</span></div>
        <h1 css={css`margin-bottom: 1rem; width: 100%;`}>{t("Vacature")}: {data.wpJob.title}</h1>
        <article css={css`width: 100%`}>{ReactHtmlParser(data.wpJob.content)}</article>
        <h3 css={css`width: 100%; margin-top: 2rem;`}><Trans>Interesse? Stuur dan je CV en motivatiebrief naar</Trans> <a href="mailto:info@asbitech.eu" className="tekstbutton-yellow">info@asbitech.eu</a></h3>
        </div>
    </Layout>
    )
}

export const query = graphql`
query($slug: String!, $language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
    wpJob (slug: {eq: $slug}, language: {locale: {eq: $language}}) {
        title
        uri
        slug
        content
        jobomschrijving {
          duur
          type
        }
      }
    }
     `